<template>
    <div class="principal">
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <div class="page-header back-image8"></div>
            <div class="curve-white">
                <img src="../../../assets/img/curve.image.png" alt="">
            </div>
            <section>
                <div class="container top140">
                    <div class="row">
                        <div class="col-md-10 col-lg-8 blog-content" style="margin-bottom: 20px;">
                            <div class="blog-date top80 text-blue">
                                <ul class="post-categories">
                                    <li>
                                        <a href="#" rel="category tag">EMSILVIA</a>
                                    </li>
                                </ul>
                                <time> {{ listNews.date }}</time>
                            </div>
                            <h1>
                                {{ listNews.title }}
                            </h1>
                            <div class="top24 text-blue excerpt">
                                <p class="excerpt">
                                    {{ listNews.description }}
                                </p>
                            </div>

                            <hr>
                            <div v-for="(item, index) in listNews.images" :key="index">
                                <h1>{{ item.name }}</h1>
                                <p>{{ item.description }}</p>
                                <div class="top24 img-container">
                                    <img width="1600" height="1200" :src="item.urlImage" alt="">
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4">
                            <div class="aside box-reciente">
                                <h4 class="em-tx"><b>Descubre más noticias de actualidad</b></h4>
                                <div class="aside-hub" v-for="(item, index) in listAllNews" :key="index">
                                    <a href="#">
                                        <div class="aside-hub-img ">
                                            <img :src="item.images[0].urlImage" alt="">
                                        </div>
                                    </a>
                                    <div class="aside-hub-text">
                                        <div class="blog-date text-blue">
                                            <ul class="post-categories">
                                                <li><a href="#" rel="category tag">EMSILVIA</a></li>
                                            </ul>
                                        </div>
                                        <router-link target="”_blank”"
                                            :to="{ name: 'publicDetailNew', params: { _id: item._id } }">{{
                                                item.title
                                            }}</router-link>

                                    </div>
                                </div>
                            </div>
                            <div class="pb-3" style="margin-bottom: 30px;">
                                <div class="bg-light py-2 px-4 mb-3">
                                    <h3 class=" h3 m-0">Sitios Web</h3>
                                </div>
                                <div class="d-flex flex-wrap m-n1">
                                    <a href="https://twitter.com/EmsilviaESP" target="_blank"
                                        class="btn btn-sm btn-outline-secondary m-1">Twitter
                                        <a-icon type="twitter" class="twitter" />
                                    </a>
                                    <a href="https://www.facebook.com/profile.php?id=100093843576095" target="_blank"
                                        class="btn btn-sm btn-outline-secondary m-1">Facebook
                                        <a-icon type="facebook" theme="filled" />
                                    </a>
                                    <a href="https://www.instagram.com/emsilvia_esp/" target="_blank"
                                        class="btn btn-sm btn-outline-secondary m-1">Instagram
                                        <a-icon type="instagram" theme="filled" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </div>
</template>
<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db } from '../../../../firebaseConfig';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault,
    },
    data() {
        return {
            listNews: [],
            isLoading: false,
            idParam: '',
            listAllNews: [],
        };
    },
    mounted() {
        this.idParam = this.$route.params._id;
        this.getAllNews();
        this.getAllDetailNews();
    },
    methods: {
        async getAllNews() {
            this.isLoading = true;
            await db.collection('news')
                .doc(this.idParam)
                .get()
                .then((res) => {
                    this.listNews = res.data();
                    this.isLoading = false;
                });
        },
        async getAllDetailNews() {
            try {
                this.isLoading = true;
                const res = await db.collection('news').get();
                const news = [];
                res.forEach((doc) => {
                    news.push(doc.data());
                });
                this.listAllNews = news;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
    }
};



</script>
<style lang="css">
.back-image8 {
    background: url('../../../assets/img/fotoDetalNews.png') no-repeat center;
    background-size: cover;
}


@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 992px) {
    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

/* @media (min-width: 768px){
.col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
} */

.col-lg-8,
.col-md-10,
.col-lg-4 {

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.text-blue {
    color: #273580;
}


.post-categories {
    padding-left: 0;
}

.post-categories {
    display: inline-block;
    margin-right: 8px;
    margin-left: auto;
}

.post-categories li:first-of-type {
    margin-right: 12px;
}

.post-categories li {
    display: inline-block;
}

.blog-content ul li {
    padding-top: 8px;
}

.post-categories li {
    list-style: none;
    text-transform: uppercase;
}

.post-categories li a {
    color: var(--colorBluePrimary);
    cursor: auto;
    font-weight: 700;
    padding-left: 0;
}

.blog-date time {
    color: #181A37;
    font-weight: 700;

}

@media (min-width: 768px) {
    .blog-content h1 {
        font-size: 50px;
    }
}

.blog-content h1 {
    line-height: 1.1;
    text-transform: none;
}

@media(max-width:400px) {
    .blog-content h1 {
        font-size: 14vw;
    }
}

@media (min-width: 768px) {
    .excerpt {
        font-size: 24px;
    }
}

.top24 {
    margin-top: 24px;
}

/* hacer una clase a p */
.blog-content p {
    margin: 0 0 24px;
    line-height: 1.5;

}

@media (min-width: 768px) {
    .excerpt {
        font-size: 24px;
    }
}

.blog-content img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 640px;
    display: inherit;
    /* margin: 0 auto 16px; */
}

/*descubre mas noticias */

@media (min-width: 992px) {
    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.box-reciente {
    background: white;
}

.aside {
    width: 100%;
    max-width: 420px;
    padding: 24px 16px 0;
    margin-top: 20px;
    position: relative;
    display: inline-block;
    border-radius: 4px;
    margin-bottom: 10px;
}

@media(max-width:400px) {
    .aside {
        padding: 0px;
    }
}

@media (min-width: 768px) {
    .em-tx {
        font-size: 20px;
    }
}

.em-tx {
    line-height: 1.3;
    margin-bottom: 4px;
    font-weight: 500;
}

.aside-hub {
    width: calc(100% + 24px);
    height: auto;
    overflow: hidden;
    display: inline-block;
    border-radius: 8px;
    margin-top: 6px;
    margin-left: -12px;
    padding: 8px 12px;
    text-decoration: none;
    position: relative;
    transition: all .3s ease-in-out;
}

@media(max-width:400px) {
    .aside-hub {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.aside-hub-img {
    width: 80px;
    height: 72px;
    background-position: center;
    background-size: cover;
    display: inline-block;
    margin-top: 4px;
}

.aside-hub-text {
    width: calc(100% - 86px);
    display: inline-block;
    vertical-align: top;
    color: #444b2e;
    line-height: 1.2;
    padding: 0 4px;
}

.aside-hub-text .blog-date {
    width: 100%;
    height: 16px;
    display: inline-block;
    margin-bottom: 2px;
}

.aside-hub::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #CCCCCC;
    position: absolute;
    bottom: 0;
    left: 0;
}

/* @media (min-width: 992px) {
    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

.col-lg-8,
.col-md-10,
.col-lg-4 {

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}



.text-blue {
    color: #273580;
}

.top80 {
    margin-top: 80px;
}

ul.post-categories {
    padding-left: 0;
}


ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul.post-categories li:first-of-type {
    margin-right: 12px;
}

ul.post-categories li {
    display: inline-block;
}



.em-date {
    color: var(--colorBluePrimary);
    text-decoration: none;
}

.blog-date time {
    color: #181A37;
}

@media (min-width: 768px) {
    .blog-content h1 {
        font-size: 56px;
    }
}

.blog-content h1 {
    font-family: 'helveticabold';
    line-height: 1.1;
    text-transform: none;
}

h1 {
    margin: 0 0 24px;
    font-weight: 500;
    color: inherit;
}

@media (min-width: 768px) {
    .excerpt {
        font-size: 24px;
    }
}

.top24 {
    margin-top: 24px;
}

.blog-content p {
    margin: 0 0 24px;
}

p {
    line-height: 1.5;
}

.top24 {
    margin-top: 24px;
}

.blog-content img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 640px;
    display: inherit;
    margin: 0 auto 16px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.bg-light {
    background-color: #E5EAEA !important;
}

.top32 {
    margin-top: 32px;
}

figure {
    margin: 0 0 1rem;
    display: block;
}



@media (min-width: 992px) {
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.box-reciente {
    background: #E5EAEA;
}

.aside {
    width: 100%;
    max-width: 420px;
    padding: 24px 16px 0;
    margin-top: 88px;
    position: relative;
    display: inline-block;
    border-radius: 4px;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    h4 {
        font-size: 20px;
    }
}

h4 {
    line-height: 1.3;
    margin-bottom: 4px;
    font-family: inherit;
    font-weight: 500;
    color: inherit;
    margin-top: 0;
}

.aside-hub {
    width: calc(100% + 24px);
    height: auto;
    overflow: hidden;
    display: inline-block;
    border-radius: 8px;
    margin-top: 6px;
    margin-left: -12px;
    padding: 8px 12px;
    text-decoration: none;
    position: relative;
    transition: all .3s ease-in-out;
}

.aside-hub-img {
    width: 80px;
    height: 72px;
    background-position: center;
    background-size: cover;
    display: inline-block;
    margin-top: 4px;
}

.aside-hub-text {
    width: calc(100% - 86px);
    display: inline-block;
    vertical-align: top;
    color: #444b2e;
    line-height: 1.2;
    padding: 0 4px;
}

.aside-hub-text .blog-date {
    width: 100%;
    height: 16px;
    display: inline-block;
    margin-bottom: 2px;
}

b,
strong {
    font-family: 'helveticabold';
    font-weight: bolder;
}

.aside-hub::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #CCCCCC;
    position: absolute;
    bottom: 0;
    left: 0;
}

.aside-hub:hover {
    background: rgba(255, 255, 255, .5);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);

} */
</style>